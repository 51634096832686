<template>
  <div>
    <v-navigation-drawer v-model="sideMenu" app absolute temporary>
      <template v-for="(item, i) in options">
        <v-list :key="i" shaped>
          <v-subheader>
            {{ item.text }}
          </v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              :id="`menu-${subitem.text.toLowerCase().replace(/ /g, '-')}`"
              :key="i"
              v-for="(subitem, i) in item.children"
              :to="subitem.link"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ subitem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="body-2">
                {{ subitem.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "navigationdrawer",
  props: ["drawer"],
  data: () => ({
    sideMenu: false,
    options: [
      {
        text: "Mantenedores",
        icon: "mdi-table",
        children: [
          {
            text: "Grupos de reglas",
            link: "/dash/maintainers/groups",
            icon: "mdi-group",
          },
          {
            text: "Reglas",
            link: "/dash/maintainers/rules",
            icon: "mdi-ruler",
          },
        ],
      },
    ],
  }),
  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
  watch: {
    drawer(v) {
      this.sideMenu = v;
    },
    sideMenu(v) {
      if (!v) {
        this.$emit("closeDrawer");
      }
    },
  },
};
</script>

<style></style>
